const nonFiction = [
  {
    title: 'When To Rob A Bank',
    author: 'Steven D. Levitt, Stephen J. Dubner',
    link: 'https://www.amazon.co.uk/When-Rob-Bank-Rogue-Economists/dp/0141980966',
    type: 'non-fiction',
    ISBN: '9780141980966',
  },
  {
    title: 'Think Like a Freak',
    author: 'Steven D. Levitt, Stephen J. Dubner',
    link: 'https://www.amazon.co.uk/Think-Like-Freak-Secrets-Economist/dp/0141980117',
    type: 'non-fiction',
    ISBN: '978-0141980119',
  },
  {
    title: 'Super Freakonomics',
    author: 'Steven D. Levitt, Stephen J. Dubner',
    link: 'https://www.amazon.co.uk/Superfreakonomics-Cooling-Patriotic-Prostitutes-Insurance-ebook/dp/B003TSE8FS',
    type: 'non-fiction',
    ISBN: '978-0062312877',
  },
  {
    title: 'Freakonomics',
    author: 'Steven D. Levitt, Stephen J. Dubner',
    link: 'https://www.amazon.co.uk/Freakonomics-Economist-Explores-Hidden-Everything/dp/0141019018',
    type: 'non-fiction',
    ISBN: '978-0141019017',
  },
  {
    title: 'Read People Like a Book',
    author: 'Patrick King',
    link: 'https://www.amazon.co.uk/Read-People-Like-Book-Understand/dp/1647432227/ref=sr_1_4?keywords=read+people+like+a+book+patrick+king&qid=1688920439&sprefix=read+people+li%2Caps%2C114&sr=8-4',
    type: 'non-fiction',
    ISBN: '1647432235',
  },
  {
    title: 'The Subtle Art of Not Giving a F***',
    author: 'Mark Manson',
    link: 'https://www.amazon.co.uk/Subtle-Art-Not-Giving-Counterintuitive-ebook/dp/B019MMUA8S',
    type: 'non-fiction',
    ISBN: '9780062899149',
  },
  {
    title: 'Black Box Thinking',
    author: 'Matthew Syed',
    link: 'https://www.amazon.co.uk/Black-Box-Thinking-Surprising-Success/dp/1473613779',
    type: 'non-fiction',
    ISBN: '978-1591848226',
  },
  {
    title: 'Atomic Habits',
    author: 'James Clear',
    link: 'https://www.amazon.co.uk/Atomic-Habits-Proven-Build-Break-ebook/dp/B01N5AX61W/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1663010862&sr=8-1',
    type: 'non-fiction',
    ISBN: '978-1847941831',
  },
  {
    title: 'Never Split The Difference',
    author: 'Chris Voss',
    link: 'https://www.amazon.co.uk/Never-Split-Difference-Negotiating-Depended-ebook/dp/B018FHCPDO/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1663010912&sr=8-1',
    type: 'non-fiction',
    ISBN: '978-1847941497',
  },
  {
    title: 'Calling Bullsh*t',
    author: 'Carl T. Bergstrom',
    link: 'https://www.amazon.com/Calling-Bullshit-Skepticism-Data-Driven-World/dp/0525509186',
    type: 'non-fiction',
    ISBN: '978-0241438107',
  },
  {
    title: 'The God Delusion',
    author: 'Richard Dawkins',
    link: 'https://www.amazon.co.uk/God-Delusion-Richard-Dawkins-ebook/dp/B0031RSA24/ref=tmm_kin_swatch_0?_encoding=UTF8&qid=1663010976&sr=8-1',
    type: 'non-fiction',
    ISBN: '978-1784161927',
  },
  {
    title: 'Creativity Inc',
    author: 'Ed Catmull',
    link: 'https://www.amazon.co.uk/Creativity-Inc-Overcoming-Unseen-Inspiration-ebook/dp/B00GUOEMA4/ref=sr_1_1?crid=2F7C2L6D88429&keywords=creativity+inc&qid=1666135070&qu=eyJxc2MiOiIxLjg3IiwicXNhIjoiMS40NCIsInFzcCI6IjEuNTgifQ%3D%3D&s=digital-text&sprefix=creativity+inc%2Cdigital-text%2C95&sr=1-1',
    type: 'non-fiction',
    ISBN: '9780553841220',
  },
  {
    title: 'Radical Focus',
    author: 'Christina Wodtke',
    link: 'https://www.amazon.co.uk/Radical-Focus-Achieving-Important-Objectives/dp/0996006052',
    type: 'non-fiction',
    ISBN: '9780996006088',
  },
  {
    title: 'How To Talk To Anybody',
    author: 'Leil Lowndes',
    link: 'https://www.amazon.co.uk/How-Talk-Anyone-Success-Relationships/dp/B013F4VXZS/ref=sr_1_1?crid=13PSUT2KBOSNK&keywords=How+To+Talk+To+Anyone&qid=1666135260&qu=eyJxc2MiOiIyLjQ4IiwicXNhIjoiMS45MiIsInFzcCI6IjIuMDUifQ%3D%3D&s=books&sprefix=how+to+talk+to+anyone%2Cstripbooks%2C81&sr=1-1',
    type: 'non-fiction',
    ISBN: '007141858X',
  },
  {
    title: 'The Selfish Gene',
    author: 'Richard Dawkins',
    link: 'https://www.amazon.com/Selfish-Gene-Anniversary-Landmark-Science-ebook/dp/B01GI5F2FS',
    type: 'non-fiction',
    ISBN: '978-0198788607',
  },
  {
    title: 'Guns, Germs and Steel',
    author: 'Jared M. Diamond',
    link: 'https://www.amazon.com/Guns-Germs-Steel-Fates-Societies/dp/0393317552',
    type: 'non-fiction',
    ISBN: '978-0099302780',
  },
  {
    title: 'Ask Your Developer',
    author: 'Jeff Lawson',
    link: 'https://www.askyourdeveloper.com/',
    type: 'non-fiction',
    ISBN: '978-1799947073',
  },
  {
    title: 'How to Win Friends and Influence People',
    author: 'Dale Carnegie',
    link: 'https://www.amazon.co.uk/How-Win-Friends-Influence-People/dp/0091906814',
    type: 'non-fiction',
    ISBN: '9781897384558',
  },
  {
    title: 'What to Say When You Talk to Your Self',
    author: 'Shad Helmstetter',
    link: 'https://www.amazon.co.uk/What-When-Talk-Your-Yourself/dp/0722525117',
    type: 'non-fiction',
    ISBN: '978-1501171994',
  },
  {
    title: 'Thinking Fast and Slow',
    author: 'Daniel Kahneman',
    link: 'https://www.amazon.co.uk/Thinking-Fast-Slow-Daniel-Kahneman/dp/0141033576/ref=asc_df_0141033576/?tag=googshopuk-21&linkCode=df0&hvadid=310856639426&hvpos=&hvnetw=g&hvrand=1422979727258545821&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045025&hvtargid=pla-394582189334&psc=1&th=1&psc=1',
    type: 'non-fiction',
    ISBN: '978-0141033570',
  },
  {
    title: 'Radical Candor',
    author: 'Kim Scott',
    link: 'https://www.amazon.co.uk/s?k=radical+candour&ref=nb_sb_noss_2',
    type: 'non-fiction',
    ISBN: '978-1427292513',
  },
  {
    title: 'The Magic of Thinking Big',
    author: 'David Shwartz',
    link: 'https://www.amazon.co.uk/Magic-Thinking-Big-David-Schwartz/dp/1785040472/ref=asc_df_1785040472/?tag=googshopuk-21&linkCode=df0&hvadid=310623486223&hvpos=&hvnetw=g&hvrand=1422979727258545821&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045025&hvtargid=pla-318700082310&psc=1&th=1&psc=1',
    type: 'non-fiction',
    ISBN: '0879800925',
  },
  {
    title: 'The Design of Everyday Things',
    author: 'Don Norman',
    link: 'https://www.amazon.co.uk/Design-Everyday-Things-Revised-Expanded/dp/0465050654/ref=asc_df_0465050654/?tag=googshopuk-21&linkCode=df0&hvadid=372372230365&hvpos=&hvnetw=g&hvrand=10419084530039797602&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045025&hvtargid=pla-416263148589&psc=1&th=1&psc=1&tag=&ref=&adgrpid=76444332877&hvpone=&hvptwo=&hvadid=372372230365&hvpos=&hvnetw=g&hvrand=10419084530039797602&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045025&hvtargid=pla-416263148589',
    type: 'non-fiction',
    ISBN: '978-0262525671',
  },
  {
    title: 'Grit',
    author: 'Angela Duckworth',
    link: 'https://www.amazon.co.uk/Grit-passion-resilience-secrets-success/dp/1785040200/ref=asc_df_1785040200/?tag=googshopuk-21&linkCode=df0&hvadid=310831412334&hvpos=&hvnetw=g&hvrand=6164769256457485011&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9045025&hvtargid=pla-349117703989&psc=1&th=1&psc=1',
    type: 'non-fiction',
    ISBN: '978-1785040207',
  },
  {
    title: 'Javascript - The Good Parts',
    author: 'Douglas Crockford',
    link: 'https://www.amazon.co.uk/JavaScript-Good-Parts-Douglas-Crockford/dp/0596517742',
    type: 'non-fiction',
    ISBN: '978-0596517748',
  },
  {
    title: 'Eloquent Javascript',
    author: 'Marijn Haverbeke',
    link: 'https://www.amazon.co.uk/Eloquent-Javascript-3rd-Introduction-Programming/dp/1593279507/ref=sr_1_1?dchild=1&keywords=eloquent+javascript&qid=1629920080&s=books&sr=1-1',
    type: 'non-fiction',
    ISBN: '978-1593279509',
  },
  {
    title: 'Pragmatic Programmer',
    author: 'David Thomas and Andrew Hunt',
    link: 'https://www.amazon.co.uk/Pragmatic-Programmer-journey-mastery-Anniversary/dp/0135957052/ref=sr_1_1?dchild=1&keywords=pragmatic+programmer&qid=1629920161&s=books&sr=1-1',
    type: 'non-fiction',
    ISBN: '978-0135957059',
  },
  {
    title: 'Fear Bubble',
    author: 'Ant Middleton',
    link: 'https://www.amazon.co.uk/Fear-Bubble-Ant-Middleton/dp/0008194688',
    type: 'non-fiction',
    ISBN: '',
  },
]

export default nonFiction
